import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Person, Lock } from '@material-ui/icons'
import { Input, Button, Label, theme } from '@provi/provi-components'
import { history } from '../../navigation/history'
import { showToast } from '../../components/Toast'
import { TextErrorInput } from '../../components/TextErrorInput'
import { LogoProvi } from '../../assets/svgs/logo-provi'
import { login } from '../../services/api'
import { asyncLocalStorage } from '../../utils'
import TopLock from '../../assets/images/loginLock.png'

import {
  ContainerText,
  Title,
  Form,
  LoginAreaWrapper,
  Section,
  RestrictArea,
  RestrictAreaText,
  InputArea,
  Logo,
  LoginLock,
  SubTitle
} from './styles'

/**
 * @typedef {object} IFormWrapper
 * @property {{ password: string, confirmPassword: string }} values
 * @property {{ password: string, confirmPassword: string }} errors
 * @property {{ password: boolean, confirmPassword: boolean }} touched
 * @property {function} handleChange
 * @property {function} handleSubmit
 * @property {function} handleBlur
 */

/** @type {React.FC<IFormWrapper>} */
const FormWrapper = ({ values, errors, handleChange, touched, handleSubmit, handleBlur }) => {
  return (
    <>
      <Section>
        <RestrictArea>
          <LoginLock alt="" src={TopLock} />
          <RestrictAreaText>Área restrita para assinatura de contratos</RestrictAreaText>
        </RestrictArea>

        <LoginAreaWrapper>
          <Logo>
            <LogoProvi />
          </Logo>

          <ContainerText>
            <Title>Bem-vindo(a) à Principia.</Title>
          </ContainerText>

          <ContainerText>
            <SubTitle>Acesse sua conta</SubTitle>
          </ContainerText>

          <Form onSubmit={handleSubmit}>
            <InputArea>
              <Label isDark text="Login" />
              <Input
                autoFocus
                id="email"
                placeholder="Email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                  style: { color: 'white' }
                }}
                value={values.email}
                type="text"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
              />

              {errors.email && touched.email && <TextErrorInput>{errors.email}</TextErrorInput>}
            </InputArea>

            <InputArea>
              <Label isDark text="Senha" />
              <Input
                placeholder="Senha"
                id="password"
                type="password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: 'white' }} />
                    </InputAdornment>
                  ),
                  style: { color: 'white' }
                }}
              />

              {errors.password && touched.password && <TextErrorInput>{errors.password}</TextErrorInput>}
            </InputArea>

            <Button
              backgroundDisabled={theme.colors.blue800}
              text="Entrar"
              disabled={!(values.email && values.password)}
              type="submit"
              marginHorizontal={0}
              marginVertical={15}
              onClick={handleSubmit}
            />
          </Form>
        </LoginAreaWrapper>
      </Section>
    </>
  )
}

export const FormFormik = withFormik({
  mapPropsToValues: () => ({ email: '' }),
  validationSchema: () => {
    const schema = {
      email: Yup.string()
        .email('Digite um email válido')
        .required('Preencha o campo Email'),
      password: Yup.string().required('Preencha o campo Senha')
    }
    return Yup.object().shape(schema)
  },

  handleSubmit: async (values, { props }) => {
    props.setIsLoading(true)

    try {
      const { email, password } = values
      const response = await login({ email, password })
      const { token, mustChangePassword, url } = response

      if (token) {
        await asyncLocalStorage.setItem('email', email)
        await asyncLocalStorage.setItem('token', token)

        if (mustChangePassword) {
          history.push('/redefinir-senha')
        } else {
          history.push(`/${url}`)
        }
      } else {
        showToast('Login ou email inválidos')
      }
    } catch (err) {
      showToast('Login ou email inválidos')
      console.log('Login ou email inválidos')
    } finally {
      props.setIsLoading(false)
    }
  }
})(FormWrapper)
