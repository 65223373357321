import React from 'react'

/**
 * @typedef {object} ILogoProvi
 * @property {boolean=} isDark
 */

/** @type {React.FC<ILogoProvi>} */
export const LogoProvi = ({ isDark = false }) => {
  return (
    <>
      {!isDark ? (
        //branco
        <svg width="220" height="130" viewBox="0 0 1500 887" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
          <title>Principia</title>
          <desc>Logo da Principia</desc>
          <g id="Camada_1_00000160183241852956807510000008065503349045038268_">
            <g>
              <g>
                <g>
                  <path
                    class="st0"
                    d="M181.06,725.47v-39.76h23.61c11.8,0,21.75-3.88,29.82-11.65c8.08-7.77,12.11-17.55,12.11-29.36
              c0-12.01-3.99-22.26-11.96-30.75s-17.66-12.74-29.05-12.74s-21.08,4.4-29.05,13.2s-11.96,19.31-11.96,31.53V796.6h-41.01V645.94
              c0-23.61,7.71-43.59,23.14-59.95s35.05-24.54,58.87-24.54c23.81,0,43.59,8.18,59.33,24.54c15.11,15.95,22.68,35.52,22.68,58.71
              c0,22.58-7.46,41.68-22.37,57.31c-14.91,15.64-33.45,23.45-55.61,23.45h-28.55V725.47z"
                  />
                  <path
                    class="st0"
                    d="M314.01,632.9c0-20.71,6.63-37.79,19.88-51.26c13.25-13.46,30.65-20.19,52.19-20.19
              c21.95,0,39.45,6.63,52.5,19.88c12.43,13.05,18.64,30.24,18.64,51.57v7.14h-41.01v-8.08c0-20.29-10.05-30.44-30.13-30.44
              c-9.12,0-16.57,2.85-22.37,8.54c-5.8,5.7-8.7,13-8.7,21.9v93.51H314V632.9H314.01z"
                  />
                  <path
                    class="st0"
                    d="M526.5,548.09h-41.32V525.1c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9
              c5.59,0,10.41,1.97,14.44,5.9c4.04,3.94,6.06,8.8,6.06,14.6V548.09z M526.5,565.49v159.98h-41.01V565.49H526.5z"
                  />
                  <path
                    class="st0"
                    d="M563.15,632.9c0-20.71,6.63-37.79,19.88-51.26c13.25-13.46,30.65-20.19,52.19-20.19
              c21.95,0,39.45,6.63,52.5,19.88c12.43,13.05,18.64,30.24,18.64,51.57v92.57h-41.01v-93.51c0-20.29-10.05-30.44-30.13-30.44
              c-9.12,0-16.57,2.85-22.37,8.54c-5.8,5.7-8.7,13-8.7,21.9v93.51h-41.01V632.9H563.15z"
                  />
                  <path
                    class="st0"
                    d="M812.91,725.47c-23.82,0-43.08-7.66-57.78-22.99c-14.71-15.32-22.06-34.38-22.06-57.16
              c0-22.78,7.35-41.78,22.06-57c14.7-15.22,33.96-22.83,57.78-22.83h32v39.45h-28.58c-12.63,0-22.83,3.83-30.6,11.49
              c-7.77,7.66-11.65,17.29-11.65,28.89c0,11.6,3.88,21.23,11.65,28.89c7.77,7.66,17.97,11.49,30.6,11.49h28.58v39.76
              L812.91,725.47L812.91,725.47z"
                  />
                  <path
                    class="st0"
                    d="M915.42,548.09H874.1V525.1c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9
              c5.59,0,10.41,1.97,14.44,5.9c4.04,3.94,6.06,8.8,6.06,14.6V548.09z M915.42,565.49v159.98h-41.01V565.49H915.42z"
                  />
                  <path
                    class="st0"
                    d="M1006.75,725.47v-39.76h23.61c11.8,0,21.75-3.88,29.82-11.65c8.08-7.77,12.11-17.55,12.11-29.36
              c0-12.01-3.99-22.26-11.96-30.75s-17.66-12.74-29.05-12.74s-21.07,4.4-29.05,13.2c-7.97,8.8-11.96,19.31-11.96,31.53V796.6
              h-41.01V645.94c0-23.61,7.71-43.59,23.14-59.95s35.05-24.54,58.87-24.54c23.81,0,43.59,8.18,59.33,24.54
              c15.11,15.95,22.68,35.52,22.68,58.71c0,22.58-7.46,41.68-22.37,57.31c-14.91,15.64-33.45,23.45-55.61,23.45h-28.55V725.47z"
                  />
                  <path
                    class="st0"
                    d="M1183.19,548.09h-41.32V525.1c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9
              c5.59,0,10.41,1.97,14.44,5.9c4.04,3.94,6.06,8.8,6.06,14.6L1183.19,548.09L1183.19,548.09z M1183.19,565.49v159.98h-41.01
              V565.49H1183.19z"
                  />
                  <path
                    class="st0"
                    d="M1318.94,725.47h-28.58c-22.16,0-40.75-7.82-55.76-23.45c-15.02-15.63-22.52-34.74-22.52-57.31
              c0-23.19,7.77-42.87,23.3-59.02s35.21-24.23,59.02-24.23s43.44,8.18,58.87,24.54s23.14,36.35,23.14,59.95v79.53h-41.01v-79.53
              c0-12.22-3.99-22.73-11.96-31.53c-7.97-8.8-17.66-13.2-29.04-13.2c-11.39,0-21.08,4.25-29.05,12.74
              c-7.97,8.49-11.96,18.75-11.96,30.75c0,11.8,4.04,21.59,12.12,29.36c8.08,7.77,18.02,11.65,29.82,11.65h23.61V725.47z"
                  />
                </g>
              </g>
              <path
                class="st0"
                d="M784.68,91.43h-9.37c-91.45,0-165.58,74.13-165.58,165.58V422.6h62.17V258.94c0-31.12,10.17-57.46,30.5-79.03
          c20.33-21.56,46.2-32.35,77.59-32.35s57.46,10.79,78.21,32.35c19.92,21.02,29.89,46.82,29.89,77.39
          c0,29.76-9.83,54.94-29.48,75.55c-19.65,20.61-44.09,30.91-73.29,30.91h-37.67v-52.41h31.12c15.56,0,28.66-5.12,39.31-15.35
          c10.65-10.24,15.97-23.13,15.97-38.69c0-15.83-5.26-29.34-15.76-40.54c-10.51-11.19-23.28-16.79-38.29-16.79
          c-15.02,0-27.78,5.8-38.29,17.4c-10.51,11.61-15.76,25.46-15.76,41.56V422.6h58.73c91.45,0,165.58-74.13,165.58-165.58l0,0
          C950.27,165.57,876.13,91.43,784.68,91.43z"
              />
            </g>
          </g>
          <g id="Camada_2_00000067931356230720101240000015887698639677494443_"></g>
        </svg>
      ) : (
        //preto
        <svg width="190" height="115" viewBox="0 0 1500 887" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
          <title>Principia</title>
          <desc>Logo da Principia</desc>
          <g id="Camada_1_00000142866543996941971190000003523143620982357402_">
            <g>
              <g>
                <g>
                  <path d="M181.06,724.95v-39.76h23.61c11.8,0,21.75-3.88,29.82-11.65c8.08-7.77,12.11-17.55,12.11-29.36
					c0-12.01-3.99-22.26-11.96-30.75s-17.66-12.74-29.05-12.74s-21.08,4.4-29.05,13.2s-11.96,19.31-11.96,31.53v150.66h-41.01
					V645.42c0-23.61,7.71-43.59,23.14-59.95s35.05-24.54,58.87-24.54c23.81,0,43.59,8.18,59.33,24.54
					c15.11,15.95,22.68,35.52,22.68,58.71c0,22.58-7.46,41.68-22.37,57.31c-14.91,15.64-33.45,23.45-55.61,23.45h-28.55V724.95z"/>
                  <path d="M314.01,632.38c0-20.71,6.63-37.79,19.88-51.26c13.25-13.46,30.65-20.19,52.19-20.19c21.95,0,39.45,6.63,52.5,19.88
					c12.43,13.05,18.64,30.24,18.64,51.57v7.14h-41.01v-8.08c0-20.29-10.05-30.44-30.13-30.44c-9.12,0-16.57,2.85-22.37,8.54
					c-5.8,5.7-8.7,13-8.7,21.9v93.51H314v-92.57H314.01z"/>
                  <path d="M526.5,547.57h-41.32v-22.99c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9c5.59,0,10.41,1.97,14.44,5.9
					c4.04,3.94,6.06,8.8,6.06,14.6V547.57z M526.5,564.97v159.98h-41.01V564.97H526.5z"/>
                  <path d="M563.15,632.38c0-20.71,6.63-37.79,19.88-51.26c13.25-13.46,30.65-20.19,52.19-20.19c21.95,0,39.45,6.63,52.5,19.88
					c12.43,13.05,18.64,30.24,18.64,51.57v92.57h-41.01v-93.51c0-20.29-10.05-30.44-30.13-30.44c-9.12,0-16.57,2.85-22.37,8.54
					c-5.8,5.7-8.7,13-8.7,21.9v93.51h-41.01v-92.57H563.15z"/>
                  <path d="M812.91,724.95c-23.82,0-43.08-7.66-57.78-22.99c-14.71-15.32-22.06-34.38-22.06-57.16c0-22.78,7.35-41.78,22.06-57
					c14.7-15.22,33.96-22.83,57.78-22.83h32v39.45h-28.58c-12.63,0-22.83,3.83-30.6,11.49c-7.77,7.66-11.65,17.29-11.65,28.89
					c0,11.6,3.88,21.23,11.65,28.89c7.77,7.66,17.97,11.49,30.6,11.49h28.58v39.76L812.91,724.95L812.91,724.95z"/>
                  <path d="M915.42,547.57H874.1v-22.99c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9c5.59,0,10.41,1.97,14.44,5.9
					c4.04,3.94,6.06,8.8,6.06,14.6V547.57z M915.42,564.97v159.98h-41.01V564.97H915.42z"/>
                  <path d="M1006.75,724.95v-39.76h23.61c11.8,0,21.75-3.88,29.82-11.65c8.08-7.77,12.11-17.55,12.11-29.36
					c0-12.01-3.99-22.26-11.96-30.75s-17.66-12.74-29.05-12.74s-21.07,4.4-29.05,13.2c-7.97,8.8-11.96,19.31-11.96,31.53v150.66
					h-41.01V645.42c0-23.61,7.71-43.59,23.14-59.95s35.05-24.54,58.87-24.54c23.81,0,43.59,8.18,59.33,24.54
					c15.11,15.95,22.68,35.52,22.68,58.71c0,22.58-7.46,41.68-22.37,57.31c-14.91,15.64-33.45,23.45-55.61,23.45h-28.55V724.95z"/>
                  <path d="M1183.19,547.57h-41.32v-22.99c0-5.8,2.02-10.66,6.06-14.6c4.04-3.93,8.96-5.9,14.76-5.9c5.59,0,10.41,1.97,14.44,5.9
					c4.04,3.94,6.06,8.8,6.06,14.6L1183.19,547.57L1183.19,547.57z M1183.19,564.97v159.98h-41.01V564.97H1183.19z"/>
                  <path d="M1318.94,724.95h-28.58c-22.16,0-40.75-7.82-55.76-23.45c-15.02-15.63-22.52-34.74-22.52-57.31
					c0-23.19,7.77-42.87,23.3-59.02s35.21-24.23,59.02-24.23s43.44,8.18,58.87,24.54s23.14,36.35,23.14,59.95v79.53h-41.01v-79.53
					c0-12.22-3.99-22.73-11.96-31.53c-7.97-8.8-17.66-13.2-29.04-13.2c-11.39,0-21.08,4.25-29.05,12.74
					c-7.97,8.49-11.96,18.75-11.96,30.75c0,11.8,4.04,21.59,12.12,29.36c8.08,7.77,18.02,11.65,29.82,11.65h23.61V724.95z"/>
                </g>
              </g>
              <path d="M784.68,90.91h-9.37c-91.45,0-165.58,74.13-165.58,165.58v165.59h62.17V258.42c0-31.12,10.17-57.46,30.5-79.03
			c20.33-21.56,46.2-32.35,77.59-32.35s57.46,10.79,78.21,32.35c19.92,21.02,29.89,46.82,29.89,77.39
			c0,29.76-9.83,54.94-29.48,75.55c-19.65,20.61-44.09,30.91-73.29,30.91h-37.67v-52.41h31.12c15.56,0,28.66-5.12,39.31-15.35
			c10.65-10.24,15.97-23.13,15.97-38.69c0-15.83-5.26-29.34-15.76-40.54c-10.51-11.19-23.28-16.79-38.29-16.79
			c-15.02,0-27.78,5.8-38.29,17.4c-10.51,11.61-15.76,25.46-15.76,41.56v163.66h58.73c91.45,0,165.58-74.13,165.58-165.58l0,0
			C950.27,165.05,876.13,90.91,784.68,90.91z"/>
            </g>
          </g>
          <g id="Camada_2_00000121986350844269189320000001352696677671297982_">
          </g>
        </svg>
      )}
    </>
  )
}
